import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Grid, Divider } from 'semantic-ui-react';

import Footer from './footer';
import Header from './header';

import './layout.css';

class Layout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          title="zehtomer.com"
          meta={[
            { name: 'description', content: 'tomer elmalem' },
            { name: 'keywords', content: 'software' },
            { name: 'charset', content: 'utf-8' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: '/laptop.png' },
            {
              rel: 'stylesheet',
              href:
                '//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Grid verticalAlign="middle" centered>
          <Grid.Row className="layoutRow">
            <Grid.Column width={8} textAlign="center">
              <Header />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="layoutRow">
            <Grid.Column width={8}>
              <Divider horizontal className="waveDivider">
                <span role="img" aria-label=":wave:">
                  👋
                </span>
              </Divider>
              <div>{this.props.children}</div>
              <Footer />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
