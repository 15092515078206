import React from 'react';
import { Container, Divider, Icon } from 'semantic-ui-react';

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Divider horizontal section>
          get in touch
        </Divider>
        <Container textAlign="center" className="contactIcons">
          <a href="mailto:tomer@tomelm.com">
            <Icon name="mail" />
          </a>
          <a href="https://github.com/tomelm">
            <Icon name="github" />
          </a>
          <a href="https://facebook.com/telmalem">
            <Icon name="facebook" />
          </a>
          <a href="https://twitter.com/zehtomer">
            <Icon name="twitter" />
          </a>
        </Container>
      </React.Fragment>
    );
  }
}

export default Footer;