import React from 'react';
import { Header as HeaderComponent } from 'semantic-ui-react';

import './header.css'

class Header extends React.Component {
  render() {
    return (
      <HeaderComponent as="h1" className="pageHeader">
        Hi, I'm Tomer
      </HeaderComponent>
    );
  }
}

export default Header;
